import { EventObserver } from "../../observer";
import Utilities from "../../Utilities";

export class OverpopulationWarningScene extends Phaser.Scene {
  public static Name = "OverpopulationWarningScene";
  private flashOverlay: Phaser.GameObjects.Rectangle;
	eventObserver: EventObserver;
  overPopulationSound:Phaser.Sound.BaseSound;

  constructor() {
    super({ key: OverpopulationWarningScene.Name });
    this.eventObserver = EventObserver.getInstance();
  }

  public create(): void {
    Utilities.LogSceneMethodEntry("OverpopulationWarningScene", "create");
    this.overPopulationSound = this.sound.add('overPopulation')
    this.flashOverlay = this.add.rectangle(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      this.cameras.main.width,
      this.cameras.main.height,
      0xff0000,
      0.3
    ).setOrigin(0.5);

    this.playOverPopulationAnimation();

    this.eventObserver.on('redonisactive', () => {
      this.scene.stop()
    }, this);


    this.tweens.add({
      targets: this.flashOverlay,
      alpha: { from: 0.3, to: 0.6 },
      duration: 500,
      ease: "Quad.easeInOut",
      yoyo: true,
      repeat: -1
    });
  }

  private playOverPopulationAnimation(): void {
    let playCount = 0;
    const maxPlays = 1;

    const onComplete = () => {
        playCount++;
        if (playCount < maxPlays) {
            this.overPopulationSound.play();
        } else {
            this.overPopulationSound.off('complete', onComplete);
        }
    };

    this.overPopulationSound.on('complete', onComplete);

    this.overPopulationSound.play({
        volume: 0.1,
        rate: 1,
        detune: 0.5,
        seek: 0,
        loop: false,
        delay: 0
    });
  }

  stop() {
    this.flashOverlay.destroy();
    this.stop();
  }

}
