import Utilities from "../Utilities";
import { Preloader } from "./BuzzBuster";

export default class Boot extends Phaser.Scene {
	/**
	 * Unique name of the scene.
	 */

	public static  Name = "Boot";

	constructor() {
		super({key: Boot.Name});
	}

	public preload(): void {
		// Preload as needed.
	}

	public create(): void {
		Utilities.LogSceneMethodEntry("Boot", "create");
		this.scene.start(Preloader.Name);
	}
}
