import Screen from "./Screen";
import { Button } from "../components";
import { promptActions } from "../types";
import { PromptElement, PromptActionElements } from "../types/promptTypes";

export class PromptScreen extends Screen {
  protected promptTitle: Phaser.GameObjects.Image;
  protected promptMessage: Phaser.GameObjects.Image;
  protected background: Phaser.GameObjects.Image;
  protected actions: Button[] = [];
  actionElements: PromptActionElements = {};
  protected imageElements: Phaser.GameObjects.Image[] = [];
  protected textElements: Phaser.GameObjects.Text[] = [];

  constructor(scene: Phaser.Scene) {
    super(scene);
  }

  setSplashBackground() {
    super.setSplashBackground();
  }

  addMask() {
    super.addMask();
    return this;
  }

  removeMask() {
    super.removeMask();
    return this;
  }

  setBackground(promptType: string, deviationX=0, deviationY=0, scaleX=1, scaleY=1, depth=10) {
    this.background = this.scene.add.image(this.scene.cameras.main.centerX + (deviationX), this.scene.cameras.main.centerY + (deviationY), promptType)
    .setOrigin(0.5, 0.5)
    .setDepth(depth)
    .setScale(scaleX, scaleY);
    return this;
  }

  setTitle(promptTitle: string, deviationX=0, deviationY=0, scaleX=1, scaleY=1, depth=10) {
    this.promptTitle = this.scene.add.image(this.scene.cameras.main.centerX + (deviationX), this.scene.cameras.main.centerY + (deviationY), promptTitle)
    .setOrigin(0.5, 0.5)
    .setDepth(depth)
    .setScale(scaleX, scaleY);
    return this;
  }

  setMessage(promptMessage: string, deviationX=0, deviationY=0, scaleX=1, scaleY=1, depth=10) {
    this.promptMessage = this.scene.add.image(this.scene.cameras.main.centerX + (deviationX), this.scene.cameras.main.centerY + (deviationY), promptMessage)
    .setOrigin(0.5, 0.5)
    .setDepth(depth)
    .setScale(scaleX, scaleY);
    return this;
  }

  setElement(element: PromptElement) {

    switch (element.type) {
      case 'image':
        this.createImageElement(element);
        return this;
      case 'text':
        this.createTextElement(element);
        return this;
      case 'callToAction':
        this.setAction(element)
        return this;
      default:
        return this;
    }

    
  }

  private createImageElement(element: PromptElement) {
    const imageElement = this.scene.add.image(this.scene.cameras.main.centerX + (element.deviationX), this.scene.cameras.main.centerY + (element.deviationY), element.elementKey)
    .setOrigin(0.5, 0.5)
    .setDepth(element.depth)
    .setScale(element.scaleX, element.scaleY)
    .setAlpha(element.alpha);
    this.imageElements.push(imageElement);
  }

  private createTextElement(element: PromptElement) {
    const textElemet = this.scene.add.text(
      this.scene.cameras.main.centerX + (element.deviationX), 
      this.scene.cameras.main.centerY + (element.deviationY),
      element.style.text,
      {
          font: element.style.font,
          color: element.style.color,
          stroke: element.style.stroke,
          strokeThickness: element.style.strokeThickness
      }
    )
    .setAlpha(element.alpha)
    .setOrigin(0.5, 0.5)
    .setDepth(element.depth)
    .setScale(element.scaleX, element.scaleY);
    this.textElements.push(textElemet);
  }

  setActions(promptActions: Array<promptActions>, deviationX=0, deviationY=0, separation=0, scaleX=1, scaleY=1, depth=10) {
    promptActions.forEach((action, index) => {
      const button = new Button(
        this.scene,
        this.scene.cameras.main.centerX + deviationX,
        this.scene.cameras.main.centerY + deviationY + (index * separation),
        action,
        scaleX,
        scaleY
      );
      button.createButton();
      this.actions.push(button);
    });
    return this;
  }

  setAction(promptAction: PromptElement) {
    const button = new Button(
      this.scene,
      this.scene.cameras.main.centerX + promptAction.deviationX,
      this.scene.cameras.main.centerY + promptAction.deviationY,
      promptAction.callToAction,
      promptAction.scaleX,
      promptAction.scaleY
    );

    const eventName = promptAction.callToAction.eventName;
    const actionBtn = button.createButton(promptAction.originX, promptAction.originY,promptAction.depth);

    if (promptAction.callToAction.onPointerDown) {
      actionBtn.on('pointerdown', (e) =>  promptAction.callToAction.onPointerDown(e))
    }
    
    this.actionElements = { [eventName]: { button, gameObject: actionBtn }, ...this.actionElements };
    return this;
  }

  setAnimation(element: PromptElement) {

    switch (element.animation.type) {
      case 'pulse':
        this.createPulseAnimation(element.animation);
        return this;
      default:
        return this;
    }
    
  }

  createPulseAnimation(animation) {
    this.scene.tweens.add({
      targets:this.actionElements[animation.targets].gameObject,
      scale: { from: animation.scale.from, to: animation.scale.to },
      duration: animation.duration,
      yoyo: animation.yoyo,
      repeat: animation.repeat,
      ease: 'Sine.easeInOut'
      // ease:'Bounce.easeInOut'
    });

  }

  show(): void {}

  hide(): void {
    this.promptTitle && this.promptTitle.destroy();
    this.promptMessage && this.promptMessage.destroy();
    this.background && this.background.destroy();    
    this.actions.length > 0 && this.actions.forEach(button => button.destroy());
    this.textElements.length > 0 && this.textElements.forEach(textElement => textElement.destroy());
    this.imageElements.length > 0 && this.imageElements.forEach(imageElement => imageElement.destroy());
    if (Object.keys(this.actionElements).length > 0) {
      Object.keys(this.actionElements).forEach( eventName => {
        this.actionElements[eventName].button.destroy()
      })
    }
   
  }
}
