import { EventObserver } from "../observer";
import { MosquitoKillingPaletteDecorator } from "./MosquitoKillingPaletteDecorator";
import { PalettePowerX2 } from "./PalettePowerX2";
import { Mosquito } from "./Mosquito";
import { MainScene, PowerUpIndicatorScene } from "../Scenes/BuzzBuster";
import { MosquitoEventHandler } from "../handlers";

export class MosquitoKillingPalette {
  private sprite: Phaser.GameObjects.Sprite;
  private scene: Phaser.Scene;
  private isCoolingDown: boolean = false;
  private cooldownTime: number = 300;
  private killsCount: number = 0;
  private mosquitoes: Mosquito[];
  private isPowerX2Active: boolean = false;
  private eventObserver: EventObserver;
  public originalImageKey: string;
  public actionImageKey: string; 
  public hitSound:Phaser.Sound.BaseSound;
  public powerUpActivation:Phaser.Sound.BaseSound;

  constructor(scene: Phaser.Scene, mosquitoes: Mosquito[]) {
    this.scene = scene;
    this.eventObserver = EventObserver.getInstance();
    this.mosquitoes = mosquitoes;
    this.originalImageKey = 'MosquitoKillingPaletteStnd';
    this.actionImageKey = 'mosquitoKillingPaletteShot';
    this.hitSound = this.scene.sound.add('hitSound');
    this.powerUpActivation = this.scene.sound.add('powerUpActivation');

    this.eventObserver.on('mosquito-killed', () => {
      this.incrementKillsCount();
    });

    this.eventObserver.on('activatePowerX2', () => {
      this.playPowerUpAnimation();
       this.applyPowerUp(new PalettePowerX2(
        this,
        this.mosquitoes,
        'mosquitoKillingPaletteX2',
        'mosquitoKillingPaletteX2Shot',
      ));
       MosquitoEventHandler.handleMosquitoEvents('palettePowerX2');
    }, scene);

    
    this.setSprite();
    
    this.sprite.on('pointerdown', this.onPointerDown, this)
    .on('pointerup', this.onPointerUp, this);

    this.scene.input.on('pointermove', this.onPointerMove, this);

  }

  private setSprite() {
    this.sprite = this.scene.physics.add.sprite(
      this.scene.cameras.main.centerX,
      this.scene.cameras.main.centerY,
      this.originalImageKey
    )
    .setScale(1)
    .setOrigin(0.5, 0)
    .setDepth(101)
    .setInteractive();

    const canvas = this.scene.game.canvas;
    if (canvas) {
      canvas.style.cursor = "none";
    }
  }

  public getToolKey(): string  {
    return this.originalImageKey;
  }

  public changeVisibility(visible: boolean): void {
    if (this.sprite) {
        this.sprite.setVisible(visible);
    }
  }

  private onPointerMove(pointer: Phaser.Input.Pointer): void {
    if (this.sprite && this.sprite.visible) {
      this.sprite.setPosition(pointer.worldX, pointer.worldY);
    } 
  }

  public onPointerDown(pointer: Phaser.Input.Pointer): void {
    this.changeImage(this.actionImageKey);
    this.handleHit()
  }
  
  public onPointerUp(pointer: Phaser.Input.Pointer): void {
    this.changeImage(this.originalImageKey);
  }

  public getMosquitoes() {
    return this.mosquitoes;
  }

  public changeImage(imageKey: string) {
    document.querySelector("canvas").style.cursor = "none";
    this.sprite.setTexture(imageKey);
  }

  public handleHit(): void {
    if (this.isCoolingDown) {
      return;
    }
    
    this.isCoolingDown = true;

    this.playHitAnimation();

    this.scene.time.delayedCall(this.cooldownTime, () => {
      this.isCoolingDown = false;
    });
  }

  public applyPowerUp(decorator: MosquitoKillingPaletteDecorator): void {
    decorator.apply();
  }

  private incrementKillsCount(): void {
    this.killsCount++;

    if (this.killsCount >= 22 && !this.isPowerX2Active) {
      this.isPowerX2Active = true;
      this.scene.scene.launch(PowerUpIndicatorScene.Name);
      this.scene.scene.bringToTop(PowerUpIndicatorScene.Name);
    }
  }

  public playHitAnimation(): void {
    this.hitSound.play({
      volume: 0.1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });
  }

  private playPowerUpAnimation(): void {
    this.powerUpActivation.play({
      volume: 0.8,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });
  }

  public getSprite(): Phaser.GameObjects.Sprite {
    return this.sprite;
  }

  public setHitArea(hitArea: Phaser.Geom.Rectangle): void {
    this.sprite.setInteractive(hitArea, Phaser.Geom.Rectangle.Contains);
  }

  public getScene() {
    return this.scene;
  }

  public destroy(): void {
    if (this.sprite) {
      this.sprite.off('pointerdown', this.onPointerDown, this);
      this.sprite.off('pointerup', this.onPointerUp, this);
      this.sprite.destroy();
      this.hitSound.destroy();
      this.powerUpActivation.destroy();
      this.sprite = null;
    }

    this.scene.input.off('pointermove', this.onPointerMove, this);
  }
  
}
