import { Button, CloseButton, Footer } from "../components";
import { BackgroundSoundControl, LayoutElements } from "../types/options";


export class SceneLayout {
  private closeButton: CloseButton;
  private scene: Phaser.Scene;
  public backgroundSound: BackgroundSoundControl;
  public button: Button;

  constructor(scene: Phaser.Scene) {
    this.scene = scene;
    this.closeButton = new CloseButton(scene);
  }

  render(layoutElements: LayoutElements) {
    this.setSceneBackground(layoutElements.backgroundImageKey)

    if (layoutElements.closeGameButton) {
      this.setCloseGameButton();
    }

    if (layoutElements.footer) {
      this.setSceneFooter();
    }

    if (layoutElements.backgroundSound) {
      this.backgroundSound = this.setSceneBackgroundSound(layoutElements.backgroundSound);
    }
  }

  private setSceneBackground(backgroundImageKey) {
    const background = this.scene.add.image(this.scene.cameras.main.centerX,0, backgroundImageKey);
		background.setOrigin(0.5, 0);
		background.setScale(this.scene.cameras.main.width / background.width);
		let baseScale = this.scene.cameras.main.height / background.height
		if((this.scene.cameras.main.width / background.width) > baseScale) baseScale = this.scene.cameras.main.width / background.width;
		background.setScale(baseScale)
  }

  private setSceneFooter() {
    const footer = Footer.create(this.scene);
  }

  private setCloseGameButton() {
    this.button = this.closeButton.create();
  }

  private setSceneBackgroundSound(soundConfig: LayoutElements["backgroundSound"]){
    const backgroundMusic: Phaser.Sound.BaseSound = this.scene.sound.add(soundConfig.key);

    return {
      play: () => {
        backgroundMusic.play({
          volume: soundConfig.volume,
          rate: soundConfig.rate,
          detune: soundConfig.detune,
          seek: soundConfig.seek,
          loop: soundConfig.loop,
          delay: soundConfig.delay
        });
      },
      stop: () => {
        backgroundMusic.stop();
      },
      destroy: () => {
        backgroundMusic.destroy();
      }
    }
  }

}

