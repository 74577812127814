import { PromptScreen } from "../Screen";
import { prompt } from "../config";

export default class LeavingGameScene extends Phaser.Scene {

  public static Name = 'LeavingGameScene';
  private prompt: PromptScreen;
  
  constructor() {
    super({ key: 'LeavingGameScene' });
    this.prompt = new PromptScreen(this);
  }

  create() {
    // const leavingScreen = new RetryScreen(this);
    // leavingScreen.addMask();
    // leavingScreen.show(prompt.leaving, "prompt_screen")
    this.prompt.addMask();
    this.prompt.setBackground("pauseScreen",0,0,1,1,100)
    .setTitle(prompt.leaving.title,0,-113,1,1,100)
    .setMessage(prompt.leaving.message,0,-20, 1.1, 1.1,100)
    .setActions(prompt.leaving.actions,0,60,50)
  }

}
