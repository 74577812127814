import { Minimalart } from "./MinimalartGames";
import { MainGame, Preloader, MemoMainMenu, MainSettings, MemoSplashScreen } from "../Scenes/MemoTest";
import { PhaserGameOptions } from "../types";

export class Memotest {

	public static selectedCoupon: string = 'PRIMAVERA-5U5Y5';

  private static config: Phaser.Types.Core.GameConfig & PhaserGameOptions = {
    width: 400 > window.innerWidth ? window.innerWidth : 400,
    height: window.innerHeight,
    type: Phaser.AUTO,
    transparent: true,
    parent: "content",
    title: "Juego de Memotest para Saphirus",
    onCloseGame: () => {},
    couponcode: "",
    scene: [ Preloader, MemoMainMenu, MemoSplashScreen, MainSettings, MainGame  ]
  };


  private static setCouponCode() {
    const coupons = [
			'PRIMAVERA-096JB', 'PRIMAVERA-1YPLD', 'PRIMAVERA-2HBCV', 'PRIMAVERA-307TT',
			'PRIMAVERA-4WXTB', 'PRIMAVERA-5U5Y5', 'PRIMAVERA-60ASF', 'PRIMAVERA-7P6DY',
			'PRIMAVERA-8S8FJ4', 'PRIMAVERA-94X04'
		];
    const randomIndex = Math.floor(Math.random() * coupons.length);
    this.config.couponcode = coupons[randomIndex];
  }

  public static getConfig() {
    this.setCouponCode();
    return this.config;
  }

  public static startScene() {
    return Preloader.Name;
  }
}
