import { prompt } from "../../config";
import { PromptScreen } from "../../Screen";
import { EventObserver } from "../../observer";
import { SceneLayout } from "../../layout/SceneLayout";
import { BuzzBuster } from "../../games";

export class MosquitoExtinctionScene extends Phaser.Scene {

  public static Name = 'MosquitoExtinction';
  winnerScreen: any;
  private eventObserver: EventObserver;
  private cloudBackground: Phaser.GameObjects.Rectangle;
  public sprayRedon:Phaser.Sound.BaseSound;
  private poisonClouds: { 
    image: Phaser.GameObjects.Image, 
    startX: number, 
    startY: number, 
    targetX: number,
    targetY: number 
  }[] = [];
  layout: SceneLayout;

  constructor() {
    super({ key: MosquitoExtinctionScene.Name });
  }

  init() {
    this.winnerScreen = new PromptScreen(this);
    this.eventObserver = EventObserver.getInstance();
    this.sprayRedon = this.sound.add('sprayRedonSound');
    this.layout = new SceneLayout(this);
  }

  create() {
    this.playSprayRedonAnimation();
    this.addPoisonClouds();
    this.setCloudBackground();

    this.eventObserver.on('mosquitoesCleared', () => {
    }, this);
  }

  private setCloudBackground() {
    this.cloudBackground = this.add.rectangle(0, 0, this.scale.width, this.scale.height, 0x58818C)
    .setOrigin(0)
    .setAlpha(0.5)
    .setDepth(99)
  }

  private playSprayRedonAnimation(): void {
    let playCount = 0;
    const maxPlays = 1;

    const onComplete = () => {
        playCount++;
        if (playCount < maxPlays) {
            this.sprayRedon.play();
        } else {
            this.sprayRedon.off('complete', onComplete);
            this.eventObserver.emit('poison-clouds-entered');
        }
    };

    this.sprayRedon.on('complete', onComplete);

    this.sprayRedon.play({
        volume: 1,
        rate: 1,
        detune: 0,
        seek: 0,
        loop: false,
        delay: 0
    });
  }  

  private addPoisonClouds(): void {

    const poisonCloudsData = [
      {
        name: 'poisonUp',
        startX: this.cameras.main.centerX,
        startY: -200,
        targetX: this.cameras.main.centerX,
        targetY: 100
      },
      {
        name: 'poisonMiddle',
        startX: this.cameras.main.centerX,
        startY: this.cameras.main.height + 200,
        targetX: this.cameras.main.centerX,
        targetY: this.cameras.main.centerY + 200
      },
      {
        name: 'poisonBottomLeft',
        startX: -200, 
        startY: this.cameras.main.height + 100,
        targetX: 100,
        targetY: this.cameras.main.height - 110 
      },
      {
        name: 'poisonBottomRight',
        startX: this.cameras.main.width + 200,
        startY: this.cameras.main.height + 200,
        targetX: this.cameras.main.width - 100, 
        targetY: this.cameras.main.height - 90 
      },
    ];
  
    poisonCloudsData.forEach((cloudData) => {
      const cloud = this.add.image(cloudData.startX, cloudData.startY, cloudData.name)
        .setScale(1.5)
        .setAlpha(1)
        .setDepth(210);
  
      this.poisonClouds.push({ 
        image: cloud, 
        startX: cloudData.startX, 
        startY: cloudData.startY, 
        targetX: cloudData.targetX, 
        targetY: cloudData.targetY 
      });
    });

    this.animatePoisonClouds();
  }
  
  private animatePoisonClouds(): void {
    this.poisonClouds.forEach((cloudObj, index) => {
      const cloud = cloudObj.image;
      const { startX, startY, targetX, targetY } = cloudObj;

      this.tweens.add({
        targets: cloud,
        x: targetX,
        y: targetY,
        duration: 2000,
        ease: 'Power2',
        onComplete: () => {
          this.time.delayedCall(1500, () => {
            this.tweens.add({
              targets: cloud,
              x: startX,
              y: startY,
              duration: 1500,
              ease: 'Power2',
              onComplete: () => {
                this.tweens.add({
                  targets: cloud,
                  alpha: 0,
                  duration: 800,
                  ease: 'Power1',
                  onComplete: () => {
                    this.cloudBackground.destroy();
                    cloud.destroy();
                    this.layout.render({
                      backgroundImageKey: "buzzbackground",
                      footer: true,
                      closeGameButton:false
                    });
                    this.setWinnerPrompt()
                  }
                });
              }
            });
          }, [], this);
        }
      });
  
      this.tweens.add({
        targets: cloud,
        alpha: { from: 1, to: 0.7 },
        duration: 800,
        yoyo: true,
        repeat: -1,
        ease: 'Sine.easeInOut'
      });
    });
  }
  
  private setWinnerPrompt() {
    this.winnerScreen.setBackground("winner_screen",0,0,1,1)
    .setTitle(prompt.win.title,0,-158,1.1,1.1)
    .setMessage(prompt.win.message,0,-50,0.9,0.9)
    .setElement({
      type: 'image',
      elementKey: 'brillo_win_der',
      deviationX:150,
      deviationY:-170,
      scaleX: 1,
      scaleY: 1,
      depth: 100
    })
    .setElement({
      type: 'image',
      elementKey: 'brillo_win_izq',
      deviationX:-150,
      deviationY:-170,
      scaleX: 1,
      scaleY: 1,
      depth: 100
    })
    .setElement({
      type: 'image',
      elementKey: 'coupon_code_bg',
      deviationX:0,
      deviationY:50,
      scaleX: 1,
      scaleY: 1,
      depth: 100
    })
    .setElement({
      type: 'text',
      style: {
        text:BuzzBuster.getConfig().couponcode,
        font: "20px montserrat-memo",
        color: "#2897A9",
        align: "center",
        stroke: "#FFFFFF",
        strokeThickness: 5
      },
      scaleX: 1.1,
      scaleY: 1.1,
      deviationX:0,
      deviationY:50,
      depth: 100,
    })
    .setAction({
      type:'callToAction',
      callToAction: {
        background:'copyCouponBtn',
        eventName: 'copy'
      },
      deviationX: 0,
      deviationY: 106,
      scaleX: 1,
      scaleY: 1,
      originX: 0.5,
      originY: 0.5,
      depth: 120,
    })
    .setAction({
      type:'callToAction',
      callToAction: {
        background:'exitSaphirusWinBtn',
        eventName: 'exit'
      },
      deviationX: 0,
      deviationY: 153,
      scaleX: 1,
      scaleY: 1,
      originX: 0.5,
      originY: 0.5,
      depth: 120
    })

    this.add.graphics()
    .fillStyle(0x000000, 0.1)
    .fillRect(0, 0, this.cameras.main.width, this.cameras.main.height)
    .setDepth(1);

  }
  
}
