import Utilities from "../../Utilities";
import { MemoMainMenu } from "./MemoMainMenu";
import { SplashScreen } from "../SplashScreen";

export class MemoSplashScreen extends Phaser.Scene {
	public static Name = "MemoSplashScreen";
	splashScreen;

	constructor() {
		super({ key: MemoSplashScreen.Name })
		this.splashScreen = new SplashScreen(this);
	}

	public preload(): void {
		this.load.path = window.assetFolder ?? "assets/";

	}

	public create(): void {
		Utilities.LogSceneMethodEntry("MemoSplashScreen", "create");

		this.time.addEvent({
			// Run after three seconds.
			delay: 1000,
			callback: this.loadMainMenu,
			callbackScope: this,
			loop: false
		});

		this.splashScreen.setBackground("background");

	}


	/**
	 * Load the next scene, the main menu.
	 */
	private loadMainMenu(): void {
		this.scene.start(MemoMainMenu.Name);
	}
}
