
export default abstract class Screen {
  scene: Phaser.Scene;
  private playButton: Phaser.GameObjects.Image;
  private mask: Phaser.GameObjects.Graphics;

  constructor(scene: Phaser.Scene) {
      this.scene = scene;
  }

  abstract show(): void;

  abstract hide(): void;

  protected setSplashBackground() {
    const background = this.scene.add.image(this.scene.cameras.main.centerX,0, "background_secondary");
		background.setOrigin(0.5, 0);
		background.setScale(this.scene.cameras.main.width / background.width);
		let baseScale = this.scene.cameras.main.height / background.height
		if((this.scene.cameras.main.width / background.width) > baseScale) baseScale = this.scene.cameras.main.width / background.width;
		background.setScale(baseScale)
  }

  protected addMask(fill=0x000000, opacity=0.5, depth=3): void {
    this.mask = this.scene.add.graphics()
    .fillStyle(fill, opacity)
    .fillRect(0, 0, this.scene.cameras.main.width, this.scene.cameras.main.height)
    .setDepth(depth);
  }

  protected removeMask(): void {
    this.mask.destroy();
  }

  protected adjustDepth(score: Phaser.GameObjects.Image, scoreText: Phaser.GameObjects.Text, depthScore:number = 10, depthScoreText:number = 10): void {
		score.setDepth(depthScore);
		scoreText.setDepth(depthScoreText);
	}
}
