import { EventObserver } from "../observer";
import { MainScene, OverpopulationWarningScene } from "../Scenes/BuzzBuster";
import { Mosquito } from "./Mosquito";

export class MosquitoFactory {
  private scene: MainScene;
  private mosquitoes: Mosquito[];
  private maxMosquitoesCount: number = 30;
  private warningPopulation: number = 25;
  private warningTriggered: boolean = false;
  eventObserver: EventObserver;
e
  constructor(scene: MainScene) {
    this.scene = scene;
    this.mosquitoes = [];
    this.eventObserver = EventObserver.getInstance();
  }

  public createMosquito(textures: string[]): void {
    
    if (this.mosquitoes.length < this.maxMosquitoesCount) {
      const mosquito = new Mosquito(this.scene, textures);
      this.mosquitoes.push(mosquito);
    }

    if (this.mosquitoes.length >= this.warningPopulation && !this.warningTriggered) {
      this.eventObserver.emit('maxPopulation');
      this.triggerWarning();
    }
  }

  public getMosquitoBySprite(sprite: Phaser.GameObjects.GameObject): Mosquito | undefined {
    return this.mosquitoes.find(mosquito => mosquito.getSprite() === sprite);
  }

  public removeMosquito(mosquito: Mosquito): void {
    const index = this.mosquitoes.indexOf(mosquito);
    if (index !== -1) {
      const deadMosquitoes = this.mosquitoes.splice(index, 1);      
    }
    
    if (this.mosquitoes.length < this.warningPopulation && this.warningTriggered) {
      this.eventObserver.emit('normalPopulation');
      this.triggerWarning('stop');
    }
  }

  public removeAllMosquitoes() {
    const mosquitoesCopy = [...this.mosquitoes];
  
    mosquitoesCopy.forEach((mosquito) => {
      mosquito.destroy();
    });
  
    this.mosquitoes = [];
  }

  public disableMosquitoInteractivity(): void {
    this.mosquitoes.forEach((mosquito) => {
      mosquito.disableInteractivity();
    });
  }
  
  private triggerWarning(action = 'play'): void {
    if (action === 'play') {
      this.scene.scene.launch(OverpopulationWarningScene.Name);
      this.warningTriggered = true;
      return;
    }

    this.scene.scene.stop(OverpopulationWarningScene.Name);
    this.warningTriggered = false;
  }

  public clearAllMosquitosWithCombinedEffects(): void {
    this.mosquitoes.forEach(mosquito => {

      const sprite = mosquito.getSprite();
      if (sprite && mosquito.isAlive()) {
        this.scene.tweens.add({
          targets: sprite,
          x: sprite.x + 10,
          yoyo: true,
          repeat: 5,
          duration: 100,
          ease: 'Sine.easeInOut'
        });
      
        // Efecto de desvanecimiento (fade out)
        this.scene.tweens.add({
          targets: sprite,
          alpha: 0,
          duration: 500,
          ease: 'Power1',
          onComplete: () => {
            mosquito.destroy();
          }
        });
      }

      mosquito.destroy();
    });

    this.mosquitoes = [];
  }

  public getCurrentMosquitoesInaWorld() {
    return this.mosquitoes;
  }

  public update(): void {
    this.mosquitoes.forEach((mosquito) => mosquito.update());
  }

  public areAllMosquitoesGone(): boolean {
    return this.mosquitoes.length === 0;
  }
}
