
export class SplashScreen {

  constructor(private scene: Phaser.Scene) {
    this.scene = scene;
  }

	public preload(): void {
		this.scene.load.path = window.assetFolder ?? "assets/";
	}

	public create(): void {

		const powerBy = this.scene.add.image(this.scene.cameras.main.centerX * 2, (this.scene.cameras.main.centerY * 2), "powerBySvg");
		
		powerBy.setOrigin(1, 1);
		powerBy.setInteractive();
		powerBy.on("pointerdown", () => {
			if(window) window.open("https://minimalart.co/", "_blank");
		});

	}

	protected setBackground(imageName: string = "background") {
		const background = this.scene.add.image(this.scene.cameras.main.centerX,0, imageName);
		background.setOrigin(0.5, 0);
		background.setScale(this.scene.cameras.main.width / background.width);
		let baseScale = this.scene.cameras.main.height / background.height
		if((this.scene.cameras.main.width / background.width) > baseScale) baseScale = this.scene.cameras.main.width / background.width;
		background.setScale(baseScale)
	}

}
