import Game from "../Game";
import copy from 'copy-to-clipboard';
import { BuzzBuster } from "../games";
import { MainScene } from "../Scenes/BuzzBuster";

export class ButtonEventHandler {

    public static state = {
        continue: {
            start:false
        }
    }

    public static handleButtonEvents(buttonId: string, scene: Phaser.Scene) {

        switch (buttonId) {
            case "close":
                ButtonEventHandler.handleCloseEvent(scene);
                break;

            case "back":
                ButtonEventHandler.handleBackEvent(scene);
                break;

            case "exit":
                if (scene.game as Game) (scene.game as Game).onCloseGame();
                // window.location.href = 'https://saphirus.com.ar/tienda/';
                break;

            case "retry":
                ButtonEventHandler.handlePlayAgain(scene);
                break;

            case "copy":
                let sound = scene.sound.add("cash");
                ButtonEventHandler.handleCopyCode(scene);
                sound.play();
                break;
            case "play":
                ButtonEventHandler.handlePlayEvent(scene);
                break;
            case "continue":
                ButtonEventHandler.handleContinueMosquitoAttack(scene)
                break;
            default:
                console.log("Botón no reconocido:", buttonId);
                break;
        }
    }

    private static handleActivaRedon(scene: MainScene) {
        scene.explodeRedon();
    }

    private static handleContinueMosquitoAttack(scene: Phaser.Scene) {
    }

    private static handlePlayEvent(scene: Phaser.Scene) {
        scene.scene.start("PreInvasionScene");
    }

    private static handleCloseEvent(scene: Phaser.Scene) {
        setTimeout(() => {
            if (scene.scene.isActive('PowerUpIndicatorScene') || scene.scene.isVisible('PowerUpIndicatorScene')) {
                scene.scene.pause('PowerUpIndicatorScene');
            }
            scene.scene.launch("LeavingGameScene");
            scene.scene.bringToTop("LeavingGameScene")
            scene.scene.pause();
        }, 300);
    }

    private static handleBackEvent(scene: Phaser.Scene) {
        if (scene.scene.isActive('PowerUpIndicatorScene') || scene.scene.isVisible('PowerUpIndicatorScene')) {
            scene.scene.resume('PowerUpIndicatorScene');
        }
        scene.scene.resume();
        scene.scene.stop("LeavingGameScene");
    }

    private static handlePlayAgain(scene: Phaser.Scene) {
        // scene.scene.restart();
        // window.restartGame();
        // scene.scene..forEach(scene => {
        //     scene.scene.restart();
        //   });
    }

    private static isIOS() {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    private static async copyToClipboard(text: string) {

      const copyResult = copy(text, {
          debug: true,
          message: 'Selecciona todo el texto en el recuadro, presiona “Copiar” y luego “Cancelar”.',
          format: 'text/plain',
      });

      if (!copyResult) {
          console.log('No se pudo copiar utilizando copy-to-clipboard');
      }
        
    }

    private static handleCopyCode(scene: Phaser.Scene) {
        // const code = (scene.game as Game).config.couponcode;
        const code = BuzzBuster.getConfig().couponcode
        ButtonEventHandler.copyToClipboard(code); // Para iOS también usamos esta lógica

        // if (ButtonEventHandler.isIOS()) {
        // } else {
        //     ButtonEventHandler.copyToClipboard(code);
        // }
    }
}
