
export class MainMenu {

	constructor(private scene: Phaser.Scene) {
		this.scene = scene;
	}

	public preload(): void {
		// Preload as needed.
	}

	public pauseScene(sceneName: string) {
		this.scene.scene.pause(sceneName)
	}

	public resumeScene(sceneName: string) {
		this.scene.scene.resume(sceneName)
	}

	public create(): void {}

  public setMusic(play: boolean = false) {
    if(play) {
		// 	this.scene.music.play("music",{
		// 		volume: 0.06,
		// 		loop: true
		// 	});
		}
  }

  public setBackground(imageName: string = "background") {
    const background = this.scene.add.image(this.scene.cameras.main.centerX,0, imageName);
		background.setOrigin(0.5, 0);
		background.setScale(this.scene.cameras.main.width / background.width);
		let baseScale = this.scene.cameras.main.height / background.height
		if((this.scene.cameras.main.width / background.width) > baseScale) baseScale = this.scene.cameras.main.width / background.width;
		background.setScale(baseScale)
  }


	public update(): void {
		// Update logic, as needed.
	}
}
