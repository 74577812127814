
export class MosquitoEventHandler {
  public static isPowerX2Active: boolean = false;

  public static handleMosquitoEvents(eventId: string, scene?: Phaser.Scene) {
    console.log(eventId);
    
    switch (eventId) {
      case 'palettePowerX2':
        MosquitoEventHandler.handlePowerX2Active();
        break;
      default:
        MosquitoEventHandler.handleDefault(eventId);
        break;
    }
  }

  private static handleDefault(eventId) {
    console.log(":", eventId);
  }

  private static handlePowerX2Active() {
    MosquitoEventHandler.isPowerX2Active = true;
  }
  
}
