import Utilities from "../../Utilities";
import { FingerPower, MosquitoFactory } from "../../entities";
import { EventObserver } from "../../observer";
import { MosquitoKillingPalette } from '../../entities/MosquitoKillingPalette';
import { SceneLayout } from "../../layout/SceneLayout";
import { Preloader } from "./Preloader";
import { PromptScreen } from "../../Screen";

export class MainScene extends Phaser.Scene {
	private eventObserver: EventObserver;
  private mosquitoFactory: MosquitoFactory;
  public static Name = "MainScene";
  public palette: MosquitoKillingPalette;
  private initialMosquitoCount: number = 5;
  private additionalMosquitoCount: number = 2;
  private maxAdditionalMosquitoCount: number = 30;
  private mosquitoSpawnInterval: number = 5000; 
	private timer: Phaser.Time.TimerEvent;
	private mosquitoCreate: Phaser.Time.TimerEvent;
	private progressBar: Phaser.GameObjects.Image;
	private limitTime = 10 * 1000;
	private maxTime = 60 * 1000;
  private splitter = {
		rows: 5,
		columns: 6,
	};
  private score: Phaser.GameObjects.Image;
	private scoreText: Phaser.GameObjects.Text;
	private scoreValue = 0;
	private targetScore = 3000;
  private timeThresholdReached: boolean = false;
  private state:string; 
  public isCellPhone: boolean = false;
  private layout: SceneLayout;
  private redonActivated: boolean = false;
  public activateRedon:Phaser.Sound.BaseSound;
  public toolIndicator:Phaser.GameObjects.Image;
  public tool:string;
  activaRedonPrompt: PromptScreen;

  constructor() {
    super({ key: MainScene.Name });
    this.layout = new SceneLayout(this);
    this.activaRedonPrompt = new PromptScreen(this);
  }

  init() {
    this.resetGameState();
    this.layout.render({
      backgroundImageKey: "buzzbackground",
      footer: true,
      closeGameButton:true
    })

		this.eventObserver = EventObserver.getInstance();
    this.eventsObserver();
    if (this.sys.game.device.os.desktop){
    }
    else{
      this.isCellPhone = true;
    }
  }

  private resetGameState(): void {
		// this.scoreValue = 0;
		this.state = "idle";
	}

  public create(): void {
    Utilities.LogSceneMethodEntry("MainScene", "create");
    this.input.topOnly = false;
    this.activateRedon = this.sound.add('activaRedonSound');
    this.tool = 'stndtool';
    this.createScore();
    this.addToolIndicator();
    
    this.mosquitoFactory = new MosquitoFactory(this);

    this.spawnMosquitoes(this.initialMosquitoCount);

    this.mosquitoCreate = this.time.addEvent({
      delay: this.mosquitoSpawnInterval,
      callback: () => {
        this.spawnMosquitoes(this.additionalMosquitoCount);
        if (this.additionalMosquitoCount < this.maxAdditionalMosquitoCount) {
          this.additionalMosquitoCount += 2;
          this.mosquitoSpawnInterval = Math.max(this.mosquitoSpawnInterval - 1000, 3000);
        }
      },
      callbackScope: this,
      loop: true
    });

    this.palette = new MosquitoKillingPalette(
      this,
      this.mosquitoFactory.getCurrentMosquitoesInaWorld()
    );

    document.querySelector("canvas").style.cursor = "none";

    if (this.isCellPhone) {
      this.palette.applyPowerUp(new FingerPower(
        this.palette,
        this.palette.getMosquitoes(),
        'pulse'
      ));
    }

    this.anims.create({
      key: 'paletteEvolution',
      frames: this.anims.generateFrameNumbers('paletteEvolution', { start: 0, end: 5 - 1 }),
      frameRate: 6,
      repeat: 0
    });

    this.eventObserver.observe(this);
  }

  private applyPoisonEffectToMosquitoes(): void {
    this.mosquitoFactory.clearAllMosquitosWithCombinedEffects()
  }

  private createScore() {
    this.score = this.add.image(this.cameras.main.centerX - 190, 60, "mosquitoScore")
		.setScale(1.1, 1.05)
		.setOrigin(0, 0.5)
    .setDepth(101);

		this.scoreText = this.add.text(
			this.cameras.main.centerX + 20,
			55,
			this.scoreValue.toString(),
			{
        font: "30px montserrat-memo",
				color: "#FFFFFF",
        align: "end",
        stroke: "#F02055",
        strokeThickness: 5
			}
		)
		.setOrigin(1, 0.5)
    .setDepth(102)
  }

  private spawnMosquitoes(count: number): void {
    for (let i = 0; i < count; i++) {
      this.mosquitoFactory.createMosquito(["Mosquito1", "Mosquito2"]);
    }
  }

  private addScore() {
		this.scoreValue = (Number(this.scoreValue) + 50);
    this.scoreText.setText(this.scoreValue.toString());
    this.tweens.add({
      targets: this.scoreText,
      y: 55 - 15,
      scaleY: 1.2, 
      duration: 100,
      ease: 'Power1',
      yoyo: true,
      onComplete: () => {
        this.tweens.add({
            targets: this.scoreText,
            y: 51, 
            duration: 100, 
            ease: 'Bounce.easeOut', 
        });
      }
    });

    if (this.scoreValue >= this.targetScore && !this.redonActivated) {
      this.redonActivated = true;
      // this.mosquitoCreate.destroy();
      console.log(this.mosquitoCreate.paused);
      
      if (this.mosquitoCreate.paused === false) {
        this.mosquitoCreate.paused = true;
      }

      this.playActivateRedonAnimation();
      this.displayRedon();
      this.mosquitoFactory.disableMosquitoInteractivity();
      this.eventObserver.emit("redonisactive");
    }
  }

  private addToolIndicator() {
		this.toolIndicator = this.add.image(this.cameras.main.centerX  + 40,60, this.tool)
		.setScale(1.1, 1.2)
		.setOrigin(0, 0.5)
    .setDepth(101);

    this.tweens.add({
      targets: this.toolIndicator,
      y: 55 - 15,
      scaleY: 1.2, 
      duration: 100,
      ease: 'Power1',
      yoyo: true,
      onComplete: () => {
        this.tweens.add({
            targets: this.toolIndicator,
            y: 60, 
            duration: 100, 
            ease: 'Bounce.easeOut', 
        });
      }
    });
  }

  private removeScore() {
    this.scoreText.destroy();
    this.score.destroy();
    this.toolIndicator.destroy();
  }

  private eventsObserver() {
    //Killed Mosquito
    this.eventObserver.on('mosquito-killed', (mosquito) => {
      this.mosquitoFactory.removeMosquito(mosquito);
      this.addScore();

      if (this.mosquitoFactory.areAllMosquitoesGone()) {
        this.spawnMosquitoes(this.additionalMosquitoCount);
      }
    }, this);

    //Mosquitoes Population
    this.eventObserver.on('maxPopulation', () => {
      this.mosquitoCreate.paused = true;
    }, this);

    this.eventObserver.on('normalPopulation', () => {
      this.mosquitoCreate.paused = false;
    }, this);

    // PoisonClouds
    this.eventObserver.on('poison-clouds-entered', () => {
      this.applyPoisonEffectToMosquitoes();
      this.eventObserver.emit("mosquitoesCleared");
    }, this);

    this.eventObserver.on('activatePowerX2', () => {
      this.tool = 'x2powertool';
      this.toolIndicator.setTexture(this.tool)
    }, this);
  }

  private displayRedon(): void {

    document.querySelector("canvas").style.cursor = "default";
    this.palette.getSprite().setVisible(false);
    this.activaRedonPrompt.setBackground("activaRedonModal",0,-20,1.16,1.1,100)
    .setMessage("activaRedonMessage",0,75,1.16,1.16,100)
    .setElement({
      type:'callToAction',
      callToAction: {
        background:'activaRedonBtnHover',
        eventName: 'activaRedon',
        onPointerDown: (el) => this.explodeRedon()
      },
      deviationX: 0,
      deviationY: 150,
      scaleX: 1.16,
      scaleY: 1.16,
      originX: 0.5,
      originY: 0.5,
      depth: 120,
    })
    .setAnimation({
      type:'animmation',
      animation: {
        targets: 'activaRedon',
        type: 'pulse',
        duration: 500,
        scale: {
          from: 1,
          to: 1.3
        },
        yoyo: true,
        repeat: 0
      }
    })

  }

  public explodeRedon(): void {
    const preloader = this.scene.get(Preloader.Name) as Preloader;
    // preloader.backgroundMusic.stop();
    this.removeScore();
    this.activaRedonPrompt.hide();
    this.activateRedon.destroy();
    this.scene.stop('PowerUpIndicatorScene');
    this.layout.button.destroy();
    this.scene.launch('MosquitoExtinction');
  }

  private playActivateRedonAnimation(): void {
    this.activateRedon.play({
      volume: 0.3,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: true,
      delay: 0
    });
  }  

  public update(time: number, delta: number): void {
    this.mosquitoFactory.update();
  }

  private timeProgressBar() {
  
		const overProgressFrame = this.add.image(
			this.cameras.main.centerX,
			this.cameras.main.centerY * 2 - 60,
			"progress_bar"
		);

		overProgressFrame.setOrigin(0.5, 0.5).setScale(1.12).setDepth(110);
		this.progressBar = overProgressFrame;
		this.splitter.rows = 6;
		this.splitter.columns = 5;
		this.startTimer();

    const progressFrame = this.add.image(
			this.cameras.main.centerX,
			this.cameras.main.centerY * 2 - 60,
			"progress_frame"
		);
		progressFrame
    .setOrigin(0.5, 0.5)
    .setDepth(109)
    .setScale(1.12);
  }

  private startTimer() {
    const newLimit = this.maxTime;
    let baseTime = newLimit;
    let progress = 1;
    const timeThreshold = newLimit * 0.9;

    this.timer = this.time.addEvent({
      delay: 100,
      callback: () => {
        baseTime -= 100;
        progress = baseTime / newLimit;
        if (this.progressBar) {
            this.progressBar.setCrop(
                0,
                0,
                this.progressBar.width * progress,
                this.progressBar.height
            );
        }

        if (baseTime <= timeThreshold && !this.timeThresholdReached) {
            this.timeThresholdReached = true;
            this.eventObserver.emit('time-threshold-reached');
        }

        if (baseTime <= 0) {
            this.timer.remove();
            this.endGame();
        }
      },
      loop: true,
    });
  }

  private endGame() { }

}
