import { MainMenu } from "../MainMenu";
import { EventObserver } from "../../observer";
import { buttons } from "../../config";
import { Button } from "../../components";
import Utilities from "../../Utilities";
import { SceneLayout } from "../../layout/SceneLayout";

export class BuzzMainMenu extends Phaser.Scene  {
  public static Name = "BuzzMainMenu";
  mainMenu;
	alreadyPlayignMusic = false
	eventObserver: EventObserver;
	playSound:Phaser.Sound.BaseSound;
	layout: SceneLayout;

  constructor() {
    super({ key: BuzzMainMenu.Name  })
    this.mainMenu = new MainMenu(this);
    this.layout = new SceneLayout(this);
  }

  public pauseScene() {
		this.mainMenu.pauseScene(BuzzMainMenu.Name)
	}

	public resumeScene() {
		this.mainMenu.resumeScene(BuzzMainMenu.Name)
	}

  init() {
		this.eventObserver = EventObserver.getInstance();
  }

  create() {
		Utilities.LogSceneMethodEntry("BuzzMainMenu", "create");
    
		this.layout.render({
      backgroundImageKey: "lobbybackground",
      footer: true,
      closeGameButton:true
    })

		this.playSound = this.sound.add('hitSound');
    const logo = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY * 0.2, "logo");
		logo.setOrigin(0.5, 0);
		logo.setScale(1.3,1.3)
		logo.setDepth(122);

    this.createPlayButton();
    this.eventObserver.observe(this);
  }

  createPlayButton() {
    const button = new Button(
      this,
      this.cameras.main.centerX + 0,
      this.cameras.main.centerY * 1.3,
      buttons.play,
      1,
      1
    );

    const playButton = button.createButton();
    
    this.tweens.add({
			targets: playButton,
			scale: 1,
			delay:200,
			duration: 600,
			ease: "Bounce.easeOut"
		});

		playButton.on("pointerdown", () => {
			this.playSoundAnimation();
		},this)
    playButton.on("pointerover", () => { playButton.setTint(0xeeeeee); }, this);
		playButton.on("pointerout", () => { playButton.setTint(0xffffff); }, this);
  }

	private playSoundAnimation(): void {
    this.playSound.play({
      volume: 0.2,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });
  }

	destroy() {
		this.playSound.destroy();
	}

}
