import { Mosquito } from "./Mosquito";
import { MosquitoKillingPalette } from "./MosquitoKillingPalette";

export abstract class MosquitoKillingPaletteDecorator {
  protected sprite: Phaser.GameObjects.Sprite;
  protected mosquitoes: Mosquito[];
  protected palette: MosquitoKillingPalette;
  protected newImageKey: string;
  protected newActionImageKey: string;


  constructor(palette: MosquitoKillingPalette,  mosquitoes: Mosquito[], newImage?: string, newActionImage?:string) {
    this.palette = palette;
    this.setSprite();
    this.mosquitoes = mosquitoes;
    this.newImageKey = newImage;
    this.newActionImageKey = newActionImage;
  }

  private setSprite() {
    this.sprite = this.palette.getSprite();
  }

  protected updateImageKeys(): void {
    this.palette.originalImageKey = this.newImageKey;
    this.palette.actionImageKey = this.newActionImageKey;

    this.changeImage();
  }

  protected setNewScale(value:number) {
    this.sprite.setScale(value)
  }

  protected setVisible(visible:boolean) {
    this.sprite.setVisible(visible);
  }

  protected disableCursor() {
    document.querySelector("canvas").style.cursor = "none";
  }

  protected setInteractivity(enable:boolean) {
    if (!enable) {
      // this.changeImage('mosquitoKillingPaletteX2');
      this.sprite.disableInteractive();
    }
  }

  protected changeImage() {
    this.sprite.setTexture(this.newImageKey);
  }

  protected getScene() {
    return this.palette.getScene();
  }

  protected  handleHit(): void {
    this.palette.handleHit()
  }

  protected playHitSound() {
    this.palette.playHitAnimation();
  }

  abstract apply(): void;
}
