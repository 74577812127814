import Utilities from "../../Utilities";
import { BuzzMainMenu } from "./BuzzMainMenu";
import { SceneLayout } from "../../layout/SceneLayout";
import { BackgroundSoundControl } from "../../types/options";

export class Preloader extends Phaser.Scene {
	/**
	 * Unique name of the scene.
	 */
	public static Name = "Preloader";
	layout: SceneLayout;
	backgroundMusic: BackgroundSoundControl;

	constructor() {
		super({ key: Preloader.Name });
	}

	public preload(): void {
		this.addProgressBar();

		this.load.path = window.assetFolder ?? "assets/";
		this.load.audio("tadaa", "tadaa.mp3");
		this.load.audio("cash", "cash-register.mp3");
		this.load.audio("hitSound", "golpe_raqueta.mp3");
		this.load.audio("powerUpShow", "power_up_show.mp3");
		this.load.audio("powerUpActivation", "power_up_activation.mp3");
		this.load.audio("previaMosquito", "previa_mosquitov2.mp3");
		this.load.audio("activaRedonSound", "aparicion_redon.mp3");
		this.load.audio("sprayRedonSound", "spray_redon.mp3");
		this.load.audio("fondoMusic", "fondo_music_mosquito.mp3");
		this.load.audio("overPopulation", "overpopulation_alarm.mp3");
		this.load.audio("countdown", "countdown.mp3");

		this.load.image("buzzbackground", "BuzzBackground.png");
		this.load.svg("lobbybackground", "loby_mosquito_bg.svg");
		this.load.image("logo", "logo_mosquito_main.png");
		this.load.image("progress_frame", "progress_bar_frame.png");
		
		this.load.image("progress_bar", "progress_bar_full.png");
		this.load.image("powerBy", "powerBy_3x.png");
		this.load.svg("powerBySvg", "powerBy400x30.svg", { width:400, height:30, scale: 1 });

		this.load.image("score", "frame-score.png");
		this.load.image("play", "play.png");
		this.load.image("reset", "reset_2x.png");	
		this.load.image("close_button", "close.png");

		this.load.svg("continue_button", "continue_button.svg", { scale: 1.2 });
		this.load.svg("brillo_izq", "brillo grande izq.svg");
		this.load.svg("brillo_der", "brillo grande der.svg");
		this.load.image("brillo_win_izq", "brillo win izq.png");
		this.load.image("brillo_win_der", "brillo win der.png");
		this.load.svg("mosquitoDead", "mosquito_dead.svg", { scale: 1 });
		this.load.svg("mosquitoDeadX2", "mosquito_deadx2.svg", { scale: 1 });
		this.load.svg("prepare_message", "prepare_message.svg", { scale: 1.2 });
		this.load.svg("prepare_title", "prepare_title.svg", { scale: 1.2 });
		this.load.svg("prepareScreen", "prepare_modal.svg", { scale: 1.2 });
		this.load.svg("pauseScreen", "pausa_modal.svg", { scale: 1.1 });
		this.load.image("pauseMessage", "pausa_message.png");
		this.load.svg("pauseTitle", "pausa_title.svg", { scale: 1.1 });
		this.load.svg("exitSaphirusBtn", "exit_saphirus.svg", { scale: 1.1 });
		this.load.svg("exitSaphirusWinBtn", "exit_saphirus_win.svg");
		this.load.svg("backGameBtn", "back_game.svg", { scale: 1.1 });
		this.load.image("upgradeBtn", "upgrade_button.png");
		this.load.svg("mosquitoScore", "mosquito_score.svg", { scale: 1.2 });
		this.load.svg("mosquitoKillingPaletteShot", "palette_standart_shot.svg", { scale: 1 });
		this.load.svg("mosquitoKillingPaletteX2Shot", "palette_x2_shot.svg", { scale: 1.5 });
		this.load.svg("winner_screen", "mosquito_win.svg");
		this.load.svg("win_title", "win_mosquit_title.svg");
		this.load.svg("win_message", "win_mosquito_message.svg", { scale: 1.1 });
		this.load.image("coupon_code_bg", "cupon_mosquito_bg.png");
		this.load.svg("copyCouponBtn", "copy_code_mosquito_btn.svg");
		this.load.image("copiedCouponBtn", "copied_button.png");
		this.load.svg("playAgainBtn", "play_again.svg");
		this.load.image("activaRedonModal", "activate_redon_modal.png");
		this.load.image("activaRedonMessage", "activa_redon_message.png");
		this.load.image("activaRedonBtn", "activa_redon_btn.png");
		this.load.image("activaRedonBtnHover", "activate_redon_btn_hover.png");
		this.load.svg("poisonBottomRight", "poison_bottom_right.svg");
		this.load.svg("poisonBottomLeft", "poison_bottom_left.svg");
		this.load.svg("poisonMiddle", "poison_middle.svg");
		this.load.svg("poisonUp", "poison_up.svg");
		this.load.svg("stndtool", "standartool.svg");
		this.load.svg("x2powertool", "x2powertool.svg");
		this.load.spritesheet('paletteEvolution', 'paletteEvolution.png', { frameWidth: 113, frameHeight: 200 });
		this.load.image('pulse', 'pulse.png');
	

		this.load.svg("MosquitoModel", "mosquito_model.svg", {scale: 1});
		this.load.image("Mosquito1", "Mosquito1.png");
		this.load.image("Mosquito2", "Mosquito2a.png");
		this.load.image("waveEffect", "waveEffect.png");
		this.load.image("MosquitoKillingPaletteStnd", "mosquitoKillingPaletteStandar.png");
		this.load.svg("mosquitoKillingPaletteX2", "mosquitoKillingPaletteX2.svg", { scale: 1.5 });

	}

	init() {
		this.layout = new SceneLayout(this);
	}

	public create(): void {
		Utilities.LogSceneMethodEntry("Preloader", "create");
		
		this.layout.render({
			backgroundImageKey: 'lobbybackground',
			footer: true,
			// backgroundSound: {
			// 	key: "fondoMusic",
			// 	volume: 0.1,
			// 	rate: 1,
			// 	detune: 0,
			// 	seek: 0,
			// 	loop: true,
			// 	delay: 0
			// }
		});

		// this.backgroundMusic = this.layout.backgroundSound;
		// this.backgroundMusic.play();
		this.scene.start(BuzzMainMenu.Name);
	}


	public update(): void {
		// preload handles updates to the progress bar, so nothing should be needed here.
	}

	/**
	 * Adds a progress bar to the display, showing the percentage of assets loaded and their name.
	 */
	private addProgressBar(): void {
		const width = this.cameras.main.width;
		const height = this.cameras.main.height;
		/** Customizable. This text color will be used around the progress bar. */
		const outerTextColor = '#ffffff';

		const progressBar = this.add.graphics();
		const progressBox = this.add.graphics();
		progressBox.fillStyle(0x009FAF, 0.8);
		progressBox.fillRect(width / 4, height / 2 - 30, width / 2, 50);
		
		const loadingText = this.make.text({
			x: width / 2,
			y: height / 2 - 50,
			text: "Cargando",
			style: {
				font: "20px montserrat",
				color: "#E2D64B"
			}
		});
		loadingText.setOrigin(0.5, 0.5);

		const percentText = this.make.text({
			x: width / 2,
			y: height / 2 - 5,
			text: "0%",
			style: {
				font: "18px montserrat",
				color: "#E2D64B"
			}
		});
		percentText.setOrigin(0.5, 0.5);

		const assetText = this.make.text({
			x: width / 2,
			y: height / 2 + 50,
			text: "",
			style: {
				font: "18px monospace",
				color: outerTextColor
			}
		});

		assetText.setOrigin(0.5, 0.5);

		this.load.on("progress", (value: number) => {
			percentText.setText(parseInt(value * 100 + "", 10) + "%");
			progressBar.clear();
			progressBar.fillStyle(0xffffff, 1);
			progressBar.fillRect((width / 4) + 10, (height / 2) - 30 + 10, (width / 2 - 10 - 10) * value, 30);
		});

		// this.load.on("fileprogress", (file: Phaser.Loader.File) => {
		// 	assetText.setText("Loading asset: " + file.key);
		// });

		this.load.on("complete", () => {
			progressBar.destroy();
			progressBox.destroy();
			loadingText.destroy();
			percentText.destroy();
			assetText.destroy();
		});
	}
}
