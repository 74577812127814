const buttons = {

  retry: {
    eventName: 'retry',
    background: "retry_button"
  },
  exitSaphirus: {
    eventName: 'exit',
    background: "exitSaphirusBtn"
  },
  back: {
    eventName: 'back',
    background: "backGameBtn"
  },
  copy: {
    eventName: 'copy',
    background: "copy_coupon_button"
  },
  close: {
    eventName: 'close',
    background: "close_button"
  },
  copied: {
    eventName: 'copy',
    background: "copied_coupon_button"
  },
  play: {
    eventName: 'play',
    background: 'play'
  },
  continue:{
    eventName: 'continue',
    background: 'continue_button'
  }
}

const prompt = {

  retry: {
    title: "vospodes_title",
    message: "retry_message",
    actions: [
      buttons.retry,
      buttons.exitSaphirus
    ],
    data: {}
  },
  narrowly: {
    title: "porpoco_title",
    message: "retry_message",
    actions: [
      buttons.retry,
      buttons.exitSaphirus
    ],
    data: {}
  },
  win: {
    title: "win_title",
    message: "win_message",
    actions: [
      buttons.copy,
      buttons.exitSaphirus
    ],
    data: {}
  },
  leaving: {
    title: "pauseTitle",
    message:"pauseMessage",
    actions: [
      buttons.back,
      buttons.exitSaphirus
    ],
    data: {}
  },
  prepare: {
    title: "prepare_title",
    message:"prepare_message",
    actions: [
      buttons.continue,
    ],
    data: {}
  }
}

export {
  prompt,
  buttons
}


