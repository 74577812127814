
export class Footer {

	private static footer:Phaser.GameObjects.Image;

  public static create(scene: Phaser.Scene) {
    Footer.footer = scene.add.image(scene.cameras.main.centerX * 2, (scene.cameras.main.centerY * 2), "powerBySvg")
		.setInteractive()
		.setScale(1.2, 1)
		.on("pointerdown", () => {
			const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
			if (window) {
				if (isIOS) {
					window.location.href = "https://minimalart.co/";
				} else {
					window.open("https://minimalart.co/", "_blank");
				}
				
			}
		})
		.setOrigin(1, 1)
    .setDepth(300);

		return Footer.footer;
  }

	public static  destroy() {
		Footer.footer.destroy();
	}

}
