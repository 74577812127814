import { EventObserver } from "../../observer";
import { MosquitoEventHandler } from "../../handlers";

export class PowerUpIndicatorScene extends Phaser.Scene {
  public static Name = 'PowerUpIndicatorScene';
  private eventObserver: EventObserver;
  private iconPower: string = 'powerPaletteX2';
  private eventName: string = 'activatePowerX2';
  private powerUpIcon: Phaser.GameObjects.Image;
  public powerSoundShow:Phaser.Sound.BaseSound;

  constructor() {
    super({ 
      key: PowerUpIndicatorScene.Name
    });
  }

  init() {
    this.eventObserver = EventObserver.getInstance();
  }

  create() {
    document.querySelector("canvas").style.cursor = "none";
    this.cameras.main.setBackgroundColor('rgba(0,0,0,0)');
    this.powerSoundShow = this.sound.add('powerUpShow');

    const xPosition = this.cameras.main.width - 50;
    const yPosition = this.cameras.main.height / 2 - 120;

    this.powerUpIcon = this.add.image(xPosition, yPosition, 'upgradeBtn')
      .setInteractive()
      .setScrollFactor(0)
      .setDepth(98);

    this.input.topOnly = true;
    this.playPulseAnimation()
    this.playPowerUpShowAnimation();

    this.powerUpIcon.on('pointerdown', () => {
      this.eventObserver.emit('activatePowerX2');
      this.powerSoundShow.destroy();
      this.scene.stop(PowerUpIndicatorScene.Name);
      document.querySelector("canvas").style.cursor = "none";
    }).on('mouseover', () => {
    });

  }

  private playPowerUpShowAnimation(): void {
    this.powerSoundShow.play({
      volume: 0.5,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });
  }

  private playPulseAnimation(): void {
    this.tweens.add({
      targets: this.powerUpIcon,
      scale: { from: 1, to: 1.5 },
      duration: 500,
      ease: 'Quad.easeInOut',
      yoyo: true,
      repeat: -1
    });
  }
}

