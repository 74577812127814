import { PromptScreen } from "../../Screen";
import Utilities from "../../Utilities";
import { prompt } from "../../config";
import { EventObserver } from "../../observer";

export class PreInvasionScene extends Phaser.Scene {
  private countdownText: Phaser.GameObjects.Text;
  private countdownSound: Phaser.Sound.BaseSound;
  private countdown: number = 3;
  public static Name = "PreInvasionScene";
  private prompt: PromptScreen;
  private eventObserver: EventObserver;

  constructor() {
    super({ key: PreInvasionScene.Name });
    this.prompt = new PromptScreen(this);
    this.eventObserver = EventObserver.getInstance();
  }

  init() {
    this.setBackground();
  }

  public create(): void {
    Utilities.LogSceneMethodEntry("PreInvasionScene", "create");
    this.createPromptPrepare();
    this.countdownSound = this.sound.add('countdown');
    this.eventObserver.on('button-clicked', (buttonid) => {
      if (buttonid === 'continue') {
        this.prompt.removeMask();
        this.prompt.hide();
        this.startCountDown();
    // this.countdownSound.stop()

      }
    }, this);
  }

  private createPromptPrepare() {
    this.prompt.addMask();
    this.prompt.setBackground("prepareScreen")
    .setTitle(prompt.prepare.title,0,-150)
    .setMessage(prompt.prepare.message,0,5)
    .setElement({
      type: 'image',
      elementKey: 'MosquitoModel',
      deviationX:120,
      deviationY:-6,
      depth: 99,
      scaleX:0.7,
      scaleY:0.7,
      alpha: 0.7
    })
    .setElement({
      type: 'image',
      elementKey: 'MosquitoModel',
      deviationX:65,
      deviationY:-90,
      depth: 99,
      scaleX:0.8,
      scaleY:0.8,
      alpha: 0.8
    })
    .setElement({
      type: 'image',
      elementKey: 'MosquitoModel',
      deviationX:105,
      deviationY:90,
      depth: 99,
      alpha: 0.9
    })
    .setActions(prompt.prepare.actions,0,145,10)
  }

  startCountDown() {
    this.add.text(this.cameras.main.centerX, this.cameras.main.centerY - 50, "LA INVASIÓN DE\n MOSQUITOS COMIENZA EN...\n", {
      font: "25px montserrat-memo",
      color: "#FFFFFF",
      align: "center",
      stroke: "#F02055",
      strokeThickness: 5
    }).setOrigin(0.5)
    .setScale(0.9)

    this.countdownText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY + 50, "3", {
      font: "130px montserrat-memo",
      color: "#F02055",
      align: "center",
      stroke: "#FFFFFF",
      strokeThickness: 6
    }).setOrigin(0.5)
    .setScale(1.1)

    this.time.addEvent({
      delay: 1000, 
      callback: this.updateCountdown,
      callbackScope: this,
      loop: true,
    });
    this.playCountDownSound()
    this.playCountdownAnimation();
  }
 
  private setBackground() {
		const background = this.add.image(this.cameras.main.centerX,0, "buzzbackground");
		background.setOrigin(0.5, 0);
		background.setScale(this.cameras.main.width / background.width);
		let baseScale = this.cameras.main.height / background.height
		if((this.cameras.main.width / background.width) > baseScale) baseScale = this.cameras.main.width / background.width;
		background.setScale(baseScale)
	}

  private updateCountdown(): void {
    this.countdown--;
    if (this.countdown > 0) {
       this.countdownText.setText(`${this.countdown}`);
       this.playCountdownAnimation();
    } else {
      this.scene.stop(PreInvasionScene.Name);
      this.scene.start("MainScene");
    }
  }

  private playCountdownAnimation(): void {
    this.tweens.add({
      targets: this.countdownText,
      scale: { from: 1, to: 1.5 },
      duration: 500,
      ease: 'Quad.easeInOut',
      yoyo: true
    });
  }

  private playCountDownSound(): void {
    this.countdownSound.play({
      volume: 0.2,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });
  }  

}
