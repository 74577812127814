import { Minimalart } from "./MinimalartGames";
import { 
  MainScene,
  Preloader,
  BuzzMainMenu,
  PreInvasionScene,
  OverpopulationWarningScene,
  PowerUpIndicatorScene,
  MosquitoExtinctionScene
} from "../Scenes/BuzzBuster";
import  LeavingGameScene  from "../Scenes/LeavingGameScene";
import { PhaserGameOptions } from "../types";

export class BuzzBuster {

  private static config: Phaser.Types.Core.GameConfig & PhaserGameOptions = {
    width: 400 > window.innerWidth ? window.innerWidth : 400,
    height: window.innerHeight,
    type: Phaser.AUTO,
    // transparent: true,
    backgroundColor: 'rgba(0,0,0,0)',
    parent: "content",
    title: "Juego de BuzzBuster para Saphirus",
    scene: [ 
      Preloader,
      BuzzMainMenu,
      PreInvasionScene,
      MainScene,
      LeavingGameScene,
      OverpopulationWarningScene,
      PowerUpIndicatorScene,
      MosquitoExtinctionScene
    ],
    physics: {
      default: 'arcade',
      arcade: {
        // debug: true
      }
    },
    couponcode: BuzzBuster.setCouponCode()
  };

  constructor() {
    
  }

  private static setCouponCode(): string {
    const coupons = [
			'REDON-096JB', 'REDON-1YPLD', 'REDON-2HBCV', 'REDON-307TT',
			'REDON-4WXTB', 'REDON-5U5Y5', 'REDON-60ASF', 'REDON-7P6DY',
			'REDON-8S8FJ4', 'REDON-94X04'
		];
    const randomIndex = Math.floor(Math.random() * coupons.length);
    return coupons[randomIndex];
  }

  public static getConfig() {
    return this.config;
  }

  public static startScene() {
    return Preloader.Name;
  }

}
