import { EventObserver } from "../observer";
import { MosquitoEventHandler } from "../handlers";
import { MainScene } from "../Scenes/BuzzBuster";
export class Mosquito {
  private sprite: Phaser.Physics.Arcade.Sprite;
  private eventObserver: EventObserver;
  private hitAreaFactor: number = 1;
  private alive: boolean = true;
  private worldBoundsCallback: Function;
  public hitSound:Phaser.Sound.BaseSound;
  private state: 'spawning' | 'active' | 'dead' = 'spawning';

  constructor(private scene: MainScene, textures: string[]) {
    this.eventObserver = EventObserver.getInstance();
    this.hitAreaFactor = MosquitoEventHandler.isPowerX2Active ? 2 : 1;
    this.initMosquito(textures);    
    this.hitSound = this.scene.sound.add('hitSound');
  }

  public playHitAnimation(): void {
    this.hitSound.play({
      volume: 0.1,
      rate: 1,
      detune: 0,
      seek: 0,
      loop: false,
      delay: 0
    });
  }

  private initMosquito(textures: string[]): void {
    this.generateInitialPosition(textures[0]);

    this.animateEntry(this.onEntryComplete.bind(this));

    this.setAnimations(textures);
  }

  private updateHitArea(): void {
    const hitArea = new Phaser.Geom.Rectangle(
      0,
      0,
      this.sprite.width * 1.6 * this.hitAreaFactor * this.sprite.scaleX,
      this.sprite.height * 1.6 * this.hitAreaFactor * this.sprite.scaleY
    );
    this.sprite.setInteractive(hitArea, Phaser.Geom.Rectangle.Contains);
  }
  
  private generateInitialPosition(texture: string): void {
    const side = Phaser.Math.Between(0, 3); // 0: izquierda, 1: derecha, 2: arriba, 3: abajo
    let startX: number, startY: number, velocityX: number, velocityY: number;
  
    if (side === 0) {
      // Izquierda
      startX = -50;
      startY = Phaser.Math.Between(0, this.scene.scale.height);
      velocityX = Phaser.Math.Between(50, 150);
      velocityY = Phaser.Math.Between(-100, 100);
    } else if (side === 1) {
      // Derecha
      startX = this.scene.scale.width + 50;
      startY = Phaser.Math.Between(0, this.scene.scale.height);
      velocityX = Phaser.Math.Between(-150, -50);
      velocityY = Phaser.Math.Between(-100, 100);
    } else if (side === 2) {
      // Arriba
      startX = Phaser.Math.Between(0, this.scene.scale.width);
      startY = -50;
      velocityX = Phaser.Math.Between(-100, 100);
      velocityY = Phaser.Math.Between(50, 150);
    } else {
      // Abajo
      startX = Phaser.Math.Between(0, this.scene.scale.width);
      startY = this.scene.scale.height + 50;
      velocityX = Phaser.Math.Between(-100, 100);
      velocityY = Phaser.Math.Between(-150, -50);
    }
  
    this.sprite = this.scene.physics.add.sprite(startX, startY, texture)
      .setScale(1.3)
      .setOrigin(0.5, 0.5)
      .setDepth(99)
      .setAlpha(0)
  
    this.sprite.setVelocity(velocityX, velocityY);

    this.updateRotation();
  }

  private animateEntry(onCompleteCallback: () => void): void {
    const targetX = Phaser.Math.Between(50, this.scene.scale.width - 50);

    this.scene.tweens.add({
      targets: this.sprite,
      x: targetX,
      alpha: 1,
      duration: 800,
      ease: 'Power1',
      onComplete: onCompleteCallback,
    });

  }

  private onEntryComplete = (): void => {
    this.setupPhysics();
    this.makeInteractive();
  };

  private setupPhysics(): void {

    if (this.sprite === undefined) return;

    this.sprite.setCollideWorldBounds(true).setBounce(1, 1);

    const speed = Phaser.Math.Between(100, 500);
    const angle = Phaser.Math.FloatBetween(0, Math.PI * 2);
    this.scene.physics.velocityFromRotation(angle, speed, this.sprite.body.velocity);
    this.updateRotation();

    this.worldBoundsCallback = (body: Phaser.Physics.Arcade.Body) => {
      if (body.gameObject === this.sprite) {
        this.changeDirection();
      }
    };

    this.sprite.body.world.on('worldbounds', this.worldBoundsCallback, this);
  }

  private updateRotation(): void {
    if (!this.sprite) {
      return;
    }

    const velocity = this.sprite.body.velocity;
    let angle = Math.atan2(velocity.y, velocity.x);
    angle += Math.PI / 2;
    this.sprite.rotation = angle;
  }
  

  private makeInteractive(): void {
    this.updateHitArea();
    this.addHandleHit();
  }

  private addHandleHit() {
    this.sprite.on("pointerdown", () => {
      this.handleHit();
    });
  }

  private setAnimations(texture: string[]): void {
    if (!this.scene.anims.exists('fly')) {
      this.scene.anims.create({
        key: 'fly',
        frames: [
          { key: texture[0] },
          { key: texture[1] }
        ],
        frameRate: 26,
        repeat: -1
      });
    }
    this.sprite.play('fly');
  }

  private handleHit(): void {
    this.eventObserver.emit('mosquito-killed', this);
    this.alive = false;
    this.sprite.destroy();

    const effectImage = this.scene.add.image(this.sprite.x, this.sprite.y, 'mosquitoDead')
    .setDepth(100)
    // .setAlpha(0.5)
    .setRotation(Phaser.Math.DegToRad(-20));

    const scaleInPower = {
      from: 0.5,
      to: 1.8
    }

    if (this.scene.isCellPhone) {
      this.playHitAnimation()
    }
    
    this.scene.tweens.add({
      targets: effectImage,
      alpha: { from: 0, to: 1 },
      scale:  MosquitoEventHandler.isPowerX2Active? scaleInPower : { from: 0.2, to: 1.5 },
      rotation: { from: Phaser.Math.DegToRad(-15), to: Phaser.Math.DegToRad(15) },
      duration: 350,
      yoyo: true,
      onComplete: () => {
        effectImage.destroy();
      }
    });

  }

  private changeDirection(): void {
    const speed = Phaser.Math.Between(100, 200);
    const currentAngle = Phaser.Math.Angle.Between(0, 0, this.sprite.body.velocity.x, this.sprite.body.velocity.y);
    const newAngle = currentAngle + Phaser.Math.FloatBetween(-Math.PI / 6, Math.PI / 6);

    this.sprite.setVelocity(
      Math.cos(newAngle) * speed,
      Math.sin(newAngle) * speed
    );

    if (this.sprite.body.velocity.x < 0) {
      this.sprite.setScale(-Math.abs(this.sprite.scaleX), this.sprite.scaleY);
    } else {
      this.sprite.setScale(Math.abs(this.sprite.scaleX), this.sprite.scaleY);
    }

    this.updateRotation();

  }

  public update(): void {
    this.updateRotation();
  }

  public getSprite(): Phaser.Physics.Arcade.Sprite {
    return this.sprite;
  }

  public isAlive(): boolean {
    return this.alive;
  }

  public disableInteractivity(): void {
    if (this.sprite) {
      this.sprite.disableInteractive();
    }
  }  

  public destroy() {
    if (this.sprite) {
      this.sprite.off('pointerdown');
      if (this.sprite.body && this.sprite.body.world) {
        this.sprite.body.world.off('worldbounds', this.worldBoundsCallback, this);
      }

      this.sprite.destroy();
      this.sprite = null;
    }

    this.alive = false;
  }
}
