import 'phaser';
import Boot from "./Scenes/Boot";
import { 
	Preloader,
	BuzzMainMenu,
	MainScene,
	PreInvasionScene,
	PowerUpIndicatorScene,
	OverpopulationWarningScene,
	MosquitoExtinctionScene
} from './Scenes/BuzzBuster';
import Utilities from "./Utilities";
import LeavingGameScene from './Scenes/LeavingGameScene';

// const gameConfig: Phaser.Types.Core.GameConfig = {
// 	width: 400 > window.innerWidth ? window.innerWidth : 400,
// 	height: window.innerHeight,
// 	type: Phaser.AUTO,
// 	transparent: true,
// 	parent: "content",
// 	title: "Juego de Memotest para Saphirus"
// };

const gameConfig: Phaser.Types.Core.GameConfig = {
	width: 400 > window.innerWidth ? window.innerWidth : 400,
	height: window.innerHeight,
	type: Phaser.AUTO,
	// transparent: true,
	backgroundColor: 'rgba(0,0,0,0)',
	parent: "content",
	title: "Juego de BuzzBuster para Saphirus",
	physics: {
		default: 'arcade',
		arcade: {
			// debug: true
		}
	},
};

export default class Game extends Phaser.Game {
	onCloseGame?: () => void;
	selectedCoupon: string = 'REDON-5U5Y5';

	constructor(config: Phaser.Types.Core.GameConfig & PhaserGameOptions) {
		Utilities.LogSceneMethodEntry("Game", "constructor");
		super(config);
		this.onCloseGame = config.onClose;
		this.scene.add(Boot.Name, Boot);
		this.scene.add(Preloader.Name, Preloader);
		this.scene.add(BuzzMainMenu.Name, BuzzMainMenu);
		this.scene.add(PreInvasionScene.Name, PreInvasionScene);
		// this.scene.add(MainGameJewel.Name, MainGameJewel);
		this.scene.add(MainScene.Name, MainScene);
		this.scene.add(PowerUpIndicatorScene.Name, PowerUpIndicatorScene);
		this.scene.add(OverpopulationWarningScene.Name, OverpopulationWarningScene);
		this.scene.add(MosquitoExtinctionScene.Name, MosquitoExtinctionScene);
		this.scene.add(LeavingGameScene.Name, LeavingGameScene);
		this.scene.start(Boot.Name);
		this.selectedCoupon = this.getRandomCoupon();
	}

	public getRandomCoupon() {
		const coupons = [
			'REDON-096JB', 'REDON-1YPLD', 'REDON-2HBCV', 'REDON-307TT',
			'REDON-4WXTB', 'REDON-5U5Y5', 'REDON-60ASF', 'REDON-7P6DY',
			'REDON-8S8FJ4', 'REDON-94X04'
		];
    const randomIndex = Math.floor(Math.random() * coupons.length);
    return coupons[randomIndex];
	}

	public getCouponCode() {
		return this.selectedCoupon;
	}
}

/**
 * Workaround for inability to scale in Phaser 3.
 * From http://www.emanueleferonato.com/2018/02/16/how-to-scale-your-html5-games-if-your-framework-does-not-feature-a-scale-manager-or-if-you-do-not-use-any-framework/
 */
function resize(game: Game): void {
    let width = 400;
    
    if (window.innerWidth === 428) { 
        width = 440;
    } else if (window.innerWidth === 414) { 
        width = 414; 
    } else if (width > window.innerWidth) {
        width = window.innerWidth;
    }

    const height = window.innerHeight;

    if (game) {
        game.scale.resize(width, height);
        game.renderer.resize(width, height);
        game.scene.scenes.forEach((scene) => {    
            if (scene.cameras.main && scene.scene.isActive()) {
                scene.cameras.main.setViewport(0, 0, width, height);
                
                const zoomFactor = window.innerWidth / 400;
                scene.cameras.main.setZoom(zoomFactor);
                
                game.scale.refresh();
                scene.cameras.main.flash(1000);
                scene.scene.stop();
                scene.scene.start();
            }
        });
    }
}

type PhaserGameOptions = {
	parent?: string;
	assetFolder? : string;
	onClose?: () => void;
	couponcode?: string;
	// more options
}

declare global {
	interface Window {
		newGame: (options: PhaserGameOptions) => void;
		assetFolder?: string;
		game?: Game;
	}
}


if (window) {
	window.newGame = (options: PhaserGameOptions) => {
		const newOptions = {...gameConfig, ...options}

		if(newOptions.assetFolder) window.assetFolder = newOptions.assetFolder;
		const game = new Game(newOptions as Phaser.Types.Core.GameConfig & PhaserGameOptions);	
		window.game = game;
		// Uncomment the following two lines if you want the game to scale to fill the entire page, but keep the game ratio.
		resize(game);
		window.addEventListener("resize", () => resize(game), true);
	}

	window.onload = (): void => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const async = urlParams.get('async')
		
		if(!async) {
			window.newGame({});
		} else {
			console.log("Waiting for instructions")
		}

	}
}
