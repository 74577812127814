import { MosquitoKillingPaletteDecorator } from "./MosquitoKillingPaletteDecorator";

export class PalettePowerX2 extends MosquitoKillingPaletteDecorator {
  

  apply(): void {
    console.log("Poder X2 activado: Incrementando el tamaño de la paleta");
    this.playSpriteAnimation();
    
    setTimeout(() => {
      this.stopSpriteAnimation();
      this.setNewScale(1);
      this.updateImageKeys();
      this.disableCursor();
    }, 700);
  }

  playSpriteAnimation(): void {
    this.sprite.anims.play('paletteEvolution');
  }

  stopSpriteAnimation(): void {
    this.sprite.anims.stop();
  }

  handleHit(): void {
    
  }
}
