import { MosquitoKillingPaletteDecorator } from "./MosquitoKillingPaletteDecorator";

export class FingerPower extends MosquitoKillingPaletteDecorator {
  

  apply(): void {
    console.log("Finger Power");
    this.setVisible(false);
    // this.setInteractivity(false); 
    this.updateImageKeys();
    this.disableCursor();

    const sprite = this.palette.getSprite();
    sprite.on('pointerdown', this.onPointerDown, this)
  }

  public onPointerDown(pointer: Phaser.Input.Pointer): void {
    this.playHitSound()

  }

}

